import { FuseNavigationItem } from "@fuse/components/navigation";
import { createAction, props } from "@ngrx/store";

export const updateFilterOptions = createAction(
    '[APP] Update Filter Options',
    props<{ filterOptions: any }>()
  );

  export const getListWithFilter = createAction(
    '[APP] Get List With Filter'
  );

  export const getListWithFilterSuccess = createAction(
    '[APP] Get List With Filter Successfully !!',
    props<{ data: any[] }>()
  );

  export const getListWithFilterFail = createAction(
    '[APP] Get List With Filter Failed !!',
    props<{ err: any }>()
  );

  export const getMyPermission = createAction(
    '[APP] Get My Permissions !!'
  );

  // export const getMyPermissionSuccess = createAction(
  //   '[APP] Get My Permissions Successfully !!',
  //   props<{ err: any }>()
  // );

  // export const getMyPermissionFail = createAction(
  //   '[APP] Get My Permissions Failed !!',
  //   props<{ err: any }>()
  // );

  export const updateNavigation = createAction(
    '[APP] Update Navigations!!',
    props<{ nav: FuseNavigationItem[] }>()
  );

  export const updateNavigationSuccess = createAction(
    '[APP] Update Navigations Successfully!!'
  );

  export const refreshToken = createAction(
    '[APP] Refresh Token!!'
  );

  export const refreshTokenSuccess = createAction(
    '[APP] Refresh Token Successfully!!'
  );

  export const logout = createAction(
    '[APP] Logout!!'
  );

  export const logoutSuccess = createAction(
    '[APP] Signed Out!!'
  );
