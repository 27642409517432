import { inject } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { PermissionService, RolePermission } from "app/services/permission";
import { AppState } from "./app.reducer";
import { Store } from "@ngrx/store";
import { AppActions } from ".";
import { map, switchMap } from "rxjs";
import { AuthService } from "app/services/auth";


export const getMyPermission = createEffect(
    (actions$ = inject(Actions), _permissionService = inject(PermissionService), _store = inject(Store<AppState>)) => {
      return actions$.pipe(
          ofType(AppActions.getMyPermission),
          switchMap(() => _permissionService.getMyPermission().pipe(map((data: RolePermission[]) => {
            return AppActions.updateNavigation({ nav: _permissionService.generateNavigation(data)});
          }))
      ));
    },
    { functional: true }
  );

  export const updateNavigation = createEffect((actions$ = inject(Actions)) => actions$.pipe(
    ofType(AppActions.updateNavigation),
    map(() => AppActions.updateNavigationSuccess()),
  ), { functional: true });

  export const refreshTokenEffect = createEffect(
    (actions$ = inject(Actions), _authService = inject(AuthService), _store = inject(Store<AppState>)) => {
      return actions$.pipe(
          ofType(AppActions.refreshToken),
          switchMap(() => _authService.refreshTokenFunc().pipe(map((data: any) => {
            return AppActions.refreshTokenSuccess();
          }))
      ));
    },
    { functional: true }
  );

  export const logoutEffect = createEffect(
    (actions$ = inject(Actions), _authService = inject(AuthService), _store = inject(Store<AppState>)) => {
      return actions$.pipe(
          ofType(AppActions.logout),
          switchMap(() => _authService.signOut().pipe(map((data: any) => {
            return AppActions.logoutSuccess()
          }))
      ));
    },
    { functional: true }
  )
